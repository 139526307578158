import jsonld from "jsonld";

export const BACKEND_KATAI = "https://api.katai.axone.xyz/graphql";
export const SMART_CONTRACT_DATAVERSE =
    "axone1xt4ahzz2x8hpkc0tk6ekte9x6crw4w6u0r67cyt3kz9syh24pd7scvlt2w";

export async function storeDataset(captcha, image, title, description) {
    const operations = {
        query: `mutation ($captcha: String!, $dataset: Upload!, $title: String!, $description: String!) {
          store(captchaToken: $captcha, data: $dataset, title: $title, description: $description) {
            id
            governance
            publication
            description
          }
        }`,
        variables: {
            captcha: captcha,
            dataset: null,
            title: title,
            description: description,
        },
    };

    const map = {
        0: ["variables.dataset"],
    };

    const formData = new FormData();
    formData.append("operations", JSON.stringify(operations));
    formData.append("map", JSON.stringify(map));
    formData.append("0", image);

    const response = await fetch(BACKEND_KATAI, {
        method: "POST",
        body: formData,
    });

    if (!response.ok) throw new Error("Network response was not ok");

    const result = await response.json();

    if (result.errors) {
        throw new Error(result.errors[0].message);
    }

    return result.data.store;
}

export async function submitClaimsMessage(vc) {
    jsonld.documentLoader = customDocumentLoader;
    const nquads = await jsonld.toRDF(vc, {
        format: "application/n-quads",
    });

    return {
        submit_claims: {
            claims: btoa(String.fromCodePoint(...new TextEncoder().encode(nquads))),
        },
    };
}

export async function submitClaim(claim, executeContractAsync, signingClient) {
    const msg = await submitClaimsMessage(claim);

    return executeContractAsync({
        signingClient,
        msg: msg,
    });
}

const xhrDocumentLoader = jsonld.documentLoaders.xhr();
const customDocumentLoader = async (url, options) => {
    if(url in JSONLD_CONTEXTS) {
        return {
            contextUrl: null,
            document: JSONLD_CONTEXTS[url],
            documentUrl: url,
        };
    }

    return xhrDocumentLoader(url);
};

const JSONLD_CONTEXTS = {
    "https://www.w3.org/2018/credentials/v1": {
      "@context": {
        "@version": 1.1,
        "@protected": true,
    
        "id": "@id",
        "type": "@type",
    
        "VerifiableCredential": {
          "@id": "https://www.w3.org/2018/credentials#VerifiableCredential",
          "@context": {
            "@version": 1.1,
            "@protected": true,
    
            "id": "@id",
            "type": "@type",
    
            "cred": "https://www.w3.org/2018/credentials#",
            "sec": "https://w3id.org/security#",
            "xsd": "http://www.w3.org/2001/XMLSchema#",
    
            "credentialSchema": {
              "@id": "cred:credentialSchema",
              "@type": "@id",
              "@context": {
                "@version": 1.1,
                "@protected": true,
    
                "id": "@id",
                "type": "@type",
    
                "cred": "https://www.w3.org/2018/credentials#",
    
                "JsonSchemaValidator2018": "cred:JsonSchemaValidator2018"
              }
            },
            "credentialStatus": {"@id": "cred:credentialStatus", "@type": "@id"},
            "credentialSubject": {"@id": "cred:credentialSubject", "@type": "@id"},
            "evidence": {"@id": "cred:evidence", "@type": "@id"},
            "expirationDate": {"@id": "cred:expirationDate", "@type": "xsd:dateTime"},
            "holder": {"@id": "cred:holder", "@type": "@id"},
            "issued": {"@id": "cred:issued", "@type": "xsd:dateTime"},
            "issuer": {"@id": "cred:issuer", "@type": "@id"},
            "issuanceDate": {"@id": "cred:issuanceDate", "@type": "xsd:dateTime"},
            "proof": {"@id": "sec:proof", "@type": "@id", "@container": "@graph"},
            "refreshService": {
              "@id": "cred:refreshService",
              "@type": "@id",
              "@context": {
                "@version": 1.1,
                "@protected": true,
    
                "id": "@id",
                "type": "@type",
    
                "cred": "https://www.w3.org/2018/credentials#",
    
                "ManualRefreshService2018": "cred:ManualRefreshService2018"
              }
            },
            "termsOfUse": {"@id": "cred:termsOfUse", "@type": "@id"},
            "validFrom": {"@id": "cred:validFrom", "@type": "xsd:dateTime"},
            "validUntil": {"@id": "cred:validUntil", "@type": "xsd:dateTime"}
          }
        },
    
        "VerifiablePresentation": {
          "@id": "https://www.w3.org/2018/credentials#VerifiablePresentation",
          "@context": {
            "@version": 1.1,
            "@protected": true,
    
            "id": "@id",
            "type": "@type",
    
            "cred": "https://www.w3.org/2018/credentials#",
            "sec": "https://w3id.org/security#",
    
            "holder": {"@id": "cred:holder", "@type": "@id"},
            "proof": {"@id": "sec:proof", "@type": "@id", "@container": "@graph"},
            "verifiableCredential": {"@id": "cred:verifiableCredential", "@type": "@id", "@container": "@graph"}
          }
        },
    
        "EcdsaSecp256k1Signature2019": {
          "@id": "https://w3id.org/security#EcdsaSecp256k1Signature2019",
          "@context": {
            "@version": 1.1,
            "@protected": true,
    
            "id": "@id",
            "type": "@type",
    
            "sec": "https://w3id.org/security#",
            "xsd": "http://www.w3.org/2001/XMLSchema#",
    
            "challenge": "sec:challenge",
            "created": {"@id": "http://purl.org/dc/terms/created", "@type": "xsd:dateTime"},
            "domain": "sec:domain",
            "expires": {"@id": "sec:expiration", "@type": "xsd:dateTime"},
            "jws": "sec:jws",
            "nonce": "sec:nonce",
            "proofPurpose": {
              "@id": "sec:proofPurpose",
              "@type": "@vocab",
              "@context": {
                "@version": 1.1,
                "@protected": true,
    
                "id": "@id",
                "type": "@type",
    
                "sec": "https://w3id.org/security#",
    
                "assertionMethod": {"@id": "sec:assertionMethod", "@type": "@id", "@container": "@set"},
                "authentication": {"@id": "sec:authenticationMethod", "@type": "@id", "@container": "@set"}
              }
            },
            "proofValue": "sec:proofValue",
            "verificationMethod": {"@id": "sec:verificationMethod", "@type": "@id"}
          }
        },
    
        "EcdsaSecp256r1Signature2019": {
          "@id": "https://w3id.org/security#EcdsaSecp256r1Signature2019",
          "@context": {
            "@version": 1.1,
            "@protected": true,
    
            "id": "@id",
            "type": "@type",
    
            "sec": "https://w3id.org/security#",
            "xsd": "http://www.w3.org/2001/XMLSchema#",
    
            "challenge": "sec:challenge",
            "created": {"@id": "http://purl.org/dc/terms/created", "@type": "xsd:dateTime"},
            "domain": "sec:domain",
            "expires": {"@id": "sec:expiration", "@type": "xsd:dateTime"},
            "jws": "sec:jws",
            "nonce": "sec:nonce",
            "proofPurpose": {
              "@id": "sec:proofPurpose",
              "@type": "@vocab",
              "@context": {
                "@version": 1.1,
                "@protected": true,
    
                "id": "@id",
                "type": "@type",
    
                "sec": "https://w3id.org/security#",
    
                "assertionMethod": {"@id": "sec:assertionMethod", "@type": "@id", "@container": "@set"},
                "authentication": {"@id": "sec:authenticationMethod", "@type": "@id", "@container": "@set"}
              }
            },
            "proofValue": "sec:proofValue",
            "verificationMethod": {"@id": "sec:verificationMethod", "@type": "@id"}
          }
        },
    
        "Ed25519Signature2018": {
          "@id": "https://w3id.org/security#Ed25519Signature2018",
          "@context": {
            "@version": 1.1,
            "@protected": true,
    
            "id": "@id",
            "type": "@type",
    
            "sec": "https://w3id.org/security#",
            "xsd": "http://www.w3.org/2001/XMLSchema#",
    
            "challenge": "sec:challenge",
            "created": {"@id": "http://purl.org/dc/terms/created", "@type": "xsd:dateTime"},
            "domain": "sec:domain",
            "expires": {"@id": "sec:expiration", "@type": "xsd:dateTime"},
            "jws": "sec:jws",
            "nonce": "sec:nonce",
            "proofPurpose": {
              "@id": "sec:proofPurpose",
              "@type": "@vocab",
              "@context": {
                "@version": 1.1,
                "@protected": true,
    
                "id": "@id",
                "type": "@type",
    
                "sec": "https://w3id.org/security#",
    
                "assertionMethod": {"@id": "sec:assertionMethod", "@type": "@id", "@container": "@set"},
                "authentication": {"@id": "sec:authenticationMethod", "@type": "@id", "@container": "@set"}
              }
            },
            "proofValue": "sec:proofValue",
            "verificationMethod": {"@id": "sec:verificationMethod", "@type": "@id"}
          }
        },
    
        "RsaSignature2018": {
          "@id": "https://w3id.org/security#RsaSignature2018",
          "@context": {
            "@version": 1.1,
            "@protected": true,
    
            "challenge": "sec:challenge",
            "created": {"@id": "http://purl.org/dc/terms/created", "@type": "xsd:dateTime"},
            "domain": "sec:domain",
            "expires": {"@id": "sec:expiration", "@type": "xsd:dateTime"},
            "jws": "sec:jws",
            "nonce": "sec:nonce",
            "proofPurpose": {
              "@id": "sec:proofPurpose",
              "@type": "@vocab",
              "@context": {
                "@version": 1.1,
                "@protected": true,
    
                "id": "@id",
                "type": "@type",
    
                "sec": "https://w3id.org/security#",
    
                "assertionMethod": {"@id": "sec:assertionMethod", "@type": "@id", "@container": "@set"},
                "authentication": {"@id": "sec:authenticationMethod", "@type": "@id", "@container": "@set"}
              }
            },
            "proofValue": "sec:proofValue",
            "verificationMethod": {"@id": "sec:verificationMethod", "@type": "@id"}
          }
        },
    
        "proof": {"@id": "https://w3id.org/security#proof", "@type": "@id", "@container": "@graph"}
      }
    }
  };
