import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Step1 from "./step1.png";
import Step2 from "./step2.png";
import Step3 from "./step3.png";

function Tutorial() {
    return (
        <Row>
            <Col lg={7} className="mx-auto py-3" id="tutorial">
                <h2 className="">How to contribute ? </h2>

                <p>
                    Every week, we will train the KatAI model on a different
                    theme using unique prompts. This guide explains how to
                    contribute to our training dataset using the decentralized
                    Axone protocol for governance.
                </p>
                <h3>How to Contribute a Training Image</h3>
                <div className="text-center">
                    <img
                        src={Step1}
                        alt="Step1 Scheme"
                        className="py-4  w-75"
                    />
                </div>
                <ul>
                    <ol>
                        <li>
                            <strong>Generate the Image</strong>: Use your
                            preferred generative AI tool (like MidJourney,
                            ChatGPT Dall-E, or NightCafe). Enter the current
                            sprint's prompt and generate an image. Save this
                            image to your device.
                        </li>
                        <p className="red text-center border border-danger my-4 p-3">
                            <strong>Current sprint prompt</strong>
                            <br></br> An old samurai cat sitting on a couch, watching a movie on TV as the end credits roll
                        </p>
                        <li>
                            <strong>Upload the Image</strong>: Navigate to the
                            'Contribute' page on our website. Provide a title
                            and a brief description (under 500 characters) for
                            your image. Upload your image (less than 2MB).
                        </li>
                        <li>
                            <strong>Connect Your Wallet</strong>: Link your
                            Keplr wallet to authenticate your contribution.
                        </li>
                        <li>
                            <strong>Submit Your Contribution</strong>: Click on
                            the 'Submit' button to finalize your entry.
                        </li>
                        Note that you need some testnet tokens to submit your
                        image.{" "}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            className="red"
                            href="https://faucet.okp4.network"
                        >
                            You can use the faucet to get $AXONE.
                        </a>
                    </ol>
                </ul>
                <h3>What Happens Next?</h3>
                <div className="text-center">
                    <img
                        src={Step2}
                        alt="Step2 Scheme"
                        className="py-4  w-75"
                    />
                </div>
                <p>
                    <strong> Data Storage and Governance</strong>: All submitted
                    images are stored on an Axone-operated server and are bound
                    by strict governance rules. Only the KatAI model and Axone
                    team (as operator of the server) has access to these images.
                    These rules are stored transparently on the Axone
                    blockchain.
                </p>
                <div className="text-center">
                    <img
                        src={Step3}
                        alt="Step3 Scheme"
                        className="py-5 w-100"
                    />
                </div>
                <p>
                    <strong>Training Process</strong>: Each week, the Axone team
                    initiates the training workflow. The Axone protocol verifies
                    that KatAI is authorized to use the images, then permits the
                    training process. KatAI is trained to generate images based
                    on the sprint's prompt, making it the first collectively
                    trained AI!
                </p>
                <p>
                    <strong>Results Sharing</strong>: Every week, we will
                    publish an image generated by KatAI.
                </p>

                <h3>Accessing KatAI</h3>
                <p>
                    Upon completion of the training cycles, KatAI will be
                    accessible to everyone.
                </p>

                <h3>Participate in Each Sprint!</h3>
                <p>
                    Your contributions are vital. Please feel encouraged to
                    participate in every sprint and help grow KatAI!
                </p>
            </Col>
        </Row>
    );
}

export default Tutorial;
