import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SolutionImg from "./SolutionImg";
import Timeline from "./Timeline";

function Solution(props) {
    return (
        <Row className="justify-content-center" id="future" {...props}>
            <Col lg={7} className="d-flex flex-column ">
                <h2 className="text-center">
                    The AI you want,
                    <br /> the solution they will need
                </h2>
                <p>
                    KatAI represents the future of AI by emphasizing
                    decentralization and ethical innovation through community
                    collaboration. It sets a benchmark for how technologies can
                    be developed, focusing on privacy and user sovereignty.
                </p>
                <p>
                    The project unfolds in three phases: data collection, AI
                    training and inference, and reward distribution, encouraging
                    active community involvement at every stage. By highlighting
                    the significance of user participation and data sovereignty,
                    KatAI seeks to drive technological innovation, fostering a
                    collaborative and inclusive atmosphere.
                </p>
                <SolutionImg />
                <div className="timeline">
                    <Timeline />
                </div>
            </Col>
        </Row>
    );
}

export default Solution;
