import {
    useAccount,
    useSuggestChainAndConnect,
    useDisconnect,
    useBalance,
    WalletType,
} from "graz";
import { Button, OverlayTrigger, Popover, Badge } from "react-bootstrap";
import { Person, BoxArrowRight } from "react-bootstrap-icons";
import KeplrIcon from "./Keplr_icon.svg";
import "./wallet.css";
import dentrite from "../../chainInfo";

const addressDisplay = (address) =>
    address ? `${address.slice(0, 6)}...${address.slice(-6)}` : "Loading...";

function Wallet() {
    const { suggestAndConnect } = useSuggestChainAndConnect();
    const { disconnect } = useDisconnect();
    const { data: account, isConnected } = useAccount();
    const {
        data: balance,
        isLoading: isLoadingBalance,
        refetch: refetchBalance,
    } = useBalance({
        denom: "uaxone",
        chainId: "axone-dentrite-1",
        bech32Address: account?.bech32Address,
    });

    const handleSuggestAndConnect = () => {
        suggestAndConnect({
            chainInfo: dentrite,
            walletType: WalletType.KEPLR,
        });
    }

    const handleBadgeClick = () => {
        if (account) {
            refetchBalance();
        }
    };

    const popover = (
        <Popover
            id="popover-basic"
            className="bg-dark text-white rounded-3"
            style={{ maxWidth: "500px" }}
        >
            <Popover.Body
                className="bg-dark text-white rounded-4"
                style={{ fontFamily: "Courier Prime, monospace" }}
            >
                {account ? (
                    <>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <div className="me-5">
                                {" "}
                                <img
                                    src={KeplrIcon}
                                    alt="Keplr Wallet"
                                    style={{ width: "25px", height: "auto" }}
                                />
                            </div>
                            <div className="me-5">
                                {addressDisplay(account.bech32Address)}
                            </div>
                            <div className="me-5">
                                {isLoadingBalance
                                    ? "Loading..."
                                    : `${(balance?.amount / 1000000).toFixed(
                                          3
                                      )} $AXONE`}
                            </div>
                            <div
                                style={{ cursor: "pointer" }}
                                onClick={disconnect}
                            >
                                <BoxArrowRight />
                            </div>
                        </div>
                    </>
                ) : (
                    "Account details not available."
                )}
            </Popover.Body>
        </Popover>
    );

    return (
        <div>
            {isConnected && account ? (
                <>
                    <span>
                        <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            containerPadding={20}
                            overlay={popover}
                        >
                            <Badge
                                bg="red"
                                style={{ cursor: "pointer" }}
                                onClick={handleBadgeClick}
                                className="custom-badge"
                            >
                                <Person className="me-2" />
                                {addressDisplay(account.bech32Address)}
                            </Badge>
                        </OverlayTrigger>
                    </span>
                </>
            ) : (
                <Button
                    onClick={handleSuggestAndConnect}
                    className="button-connect"
                >
                    Connect Wallet
                </Button>
            )}
        </div>
    );
}

export default Wallet;
