import React, { useCallback, useState } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Button, Container, Form, Modal, Spinner } from "react-bootstrap";
import BackToTop from "../components/BackToTop/BackToTop";
import { FileX } from "react-bootstrap-icons";
import { useExecuteContract, useCosmWasmSigningClient } from "graz";
import "./contribute.css";
import { useAccount } from "graz";
import { storeDataset, submitClaim, SMART_CONTRACT_DATAVERSE } from "./api";
import {
    GoogleReCaptcha,
    GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import Tutorial from "../components/Tutorial/Tutorial";

const EXPLORER = "https://explore.axone.xyz/Axone%20testnet/tx";
const IMAGE_MAX_SIZE = 2 * 1024 * 1024;

function Contribute() {
    return (
        <div className="Contribute p-3 p-sm-4" style={{ minHeight: "100vh" }}>
            <Container>
                <Header />
                <ContributeForm />
                <Tutorial />
            </Container>
            <Container fluid className="bgblack">
                <Footer />
                <BackToTop />
            </Container>
        </div>
    );
}

function ContributeForm() {
    const [validated, setValidated] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [selectedImage, setSelectedImage] = useState(null);
    const [captchaToken, setCaptchaToken] = useState();
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

    const [processing, setProcessing] = useState(false);
    const [progress, setProgress] = useState(0);
    const { isConnected } = useAccount();
    const { data: signingClient } = useCosmWasmSigningClient({
        opts: {
            gasPrice: "10000uknow",
        },
    });
    const { executeContractAsync } = useExecuteContract({
        contractAddress: SMART_CONTRACT_DATAVERSE,
        onError: (_) => {
            setError("Failed to execute transaction");
        },
    });

    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        if (files.length !== 0) {
            const file = files[0];
            setSelectedImage(file);

            if (file.size > IMAGE_MAX_SIZE) {
                event.target.classList.add("is-invalid");
                return;
            }
            event.target.classList.remove("is-invalid");
        }
    };

    const clearSelectedImage = () => {
        setSelectedImage(null);
        document.getElementById("upload_image_background").value = "";
    };

    const clearForm = () => {
        setTitle("");
        setDescription("");
        clearSelectedImage();
    };

    const handleSubmit = async (event) => {
        const form = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }
        setValidated(false);
        setProcessing(true);

        try {
            const store = await storeDataset(
                captchaToken,
                selectedImage,
                title,
                description
            ).catch((e) => {
                setError("Failed to store dataset.");
                throw e;
            });

            setProgress(1);
            const govResult = await submitClaim(
                store.governance,
                executeContractAsync,
                signingClient
            );
            setProgress(2);
            const descResult = await submitClaim(
                store.description,
                executeContractAsync,
                signingClient
            );
            setProgress(3);
            const pubResult = await submitClaim(
                store.publication,
                executeContractAsync,
                signingClient
            );

            setSuccess({
                datasetDID: store.id,
                govVCTx: govResult.transactionHash,
                descVCTx: descResult.transactionHash,
                pubVCTx: pubResult.transactionHash,
            });
        } catch (e) {
            console.error("Error:", e);
        } finally {
            setRefreshReCaptcha((r) => !r);
            setProcessing(false);
            setProgress(0);
            clearForm();
        }
    };

    const onVerifyReCaptcha = useCallback((token) => {
        setCaptchaToken(token);
    }, []);

    const handleCloseSuccessModal = () => {
        setSuccess(null);
    };

    const handleCloseErrorModal = () => {
        setError(null);
    };

    return (
        <Container className="mt-5">
            <h1 className="mb-3 text-center">Contribute</h1>
            <p
                className="red text-center border border-danger my-4 p-3 mb-4 mx-auto"
                style={{ maxWidth: "500px" }}
            >
                <strong>Current sprint prompt - Sprint #9</strong>
                <br></br> An old samurai cat sitting on a couch, watching a movie on TV as the end credits roll
            </p>
            <p className="mb-4 mx-auto" style={{ maxWidth: "500px" }}>
                A new approach to data orchestration, interoperable workflows,
                and decentralized sovereignty. Learn how to turn private data
                into generative AI creations, fostering a community where
                everyone contributes, owns, and governs together.<br></br>
                <br></br>
                <a href="#tutorial" className="red py-4">
                    Find a quick tutorial below
                </a>
            </p>

            <Form
                className="mx-auto"
                style={{ maxWidth: "500px" }}
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
            >
                <Form.Group className="mb-3" controlId="title">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                        type="text"
                        name="title"
                        required
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        maxLength="50"
                    />
                    <Form.Control.Feedback type="invalid">
                        Provide a title
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-5" controlId="description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                        type="text"
                        name="description"
                        required
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                        maxLength="500"
                    />
                    <Form.Control.Feedback type="invalid">
                        Provide a description for the image
                    </Form.Control.Feedback>
                </Form.Group>

                <fieldset className="upload_dropZone text-center p-4 mb-3">
                    <legend className="visually-hidden">Image uploader</legend>
                    <svg
                        className="upload_svg mb-4"
                        width="60"
                        height="60"
                        aria-hidden="true"
                    >
                        <use href="#icon-imageUpload"></use>
                    </svg>
                    <p className="my-2">
                        Drag &amp; Drop background image(s) inside dashed region
                        <br />
                        <i>or</i>
                    </p>
                    <Form.Control
                        type="file"
                        id="upload_image_background"
                        required
                        data-post-name="image_background"
                        data-post-url="https://katai.vercel.app/image/uploads/backgrounds/"
                        className="invisible"
                        accept="image/jpeg, image/png"
                        onChange={handleImageChange}
                    />
                    <Form.Label
                        htmlFor="upload_image_background"
                        className="btn btn-upload"
                    >
                        Choose file
                    </Form.Label>
                    <Form.Control.Feedback type="invalid">
                        Provide an image (max 2MB)
                    </Form.Control.Feedback>
                    {selectedImage ? (
                        <div className="file-preview">
                            <span className="file-name">
                                {selectedImage.name}
                            </span>
                            <FileX
                                role="button"
                                onClick={() => clearSelectedImage()}
                            >
                                Remove
                            </FileX>
                        </div>
                    ) : (
                        <div className="file-preview">
                            <span className="file-name">No file selected</span>
                        </div>
                    )}
                    <div className="upload_gallery d-flex flex-wrap justify-content-center gap-3 mb-0"></div>
                </fieldset>
                <GoogleReCaptchaProvider
                    reCaptchaKey="6Lc6zLgpAAAAAO_nNx5KM_u1FISe36p2k8ZmBI5E"
                    useEnterprise={true}
                >
                    <GoogleReCaptcha
                        onVerify={onVerifyReCaptcha}
                        refreshReCaptcha={refreshReCaptcha}
                    />
                    <div className="overflow-auto">
                        <Button
                            variant="primary"
                            type="submit"
                            className="btn px-4 py-2 my-5 me-4 bgblack btn-submit float-end"
                            disabled={
                                processing || !isConnected || !signingClient
                            }
                        >
                            {processing ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="me-2"
                                    />
                                    {progress}/3 Processing...
                                </>
                            ) : (
                                "Submit"
                            )}
                        </Button>
                    </div>
                </GoogleReCaptchaProvider>
            </Form>
            <svg style={{ display: "none" }}>
                <defs>
                    <symbol
                        id="icon-imageUpload"
                        clipRule="evenodd"
                        viewBox="0 0 96 96"
                    >
                        <path d="M47 6a21 21 0 0 0-12.3 3.8c-2.7 2.1-4.4 5-4.7 7.1-5.8 1.2-10.3 5.6-10.3 10.6 0 6 5.8 11 13 11h12.6V22.7l-7.1 6.8c-.4.3-.9.5-1.4.5-1 0-2-.8-2-1.7 0-.4.3-.9.6-1.2l10.3-8.8c.3-.4.8-.6 1.3-.6.6 0 1 .2 1.4.6l10.2 8.8c.4.3.6.8.6 1.2 0 1-.9 1.7-2 1.7-.5 0-1-.2-1.3-.5l-7.2-6.8v15.6h14.4c6.1 0 11.2-4.1 11.2-9.4 0-5-4-8.8-9.5-9.4C63.8 11.8 56 5.8 47 6Zm-1.7 42.7V38.4h3.4v10.3c0 .8-.7 1.5-1.7 1.5s-1.7-.7-1.7-1.5Z M27 49c-4 0-7 2-7 6v29c0 3 3 6 6 6h42c3 0 6-3 6-6V55c0-4-3-6-7-6H28Zm41 3c1 0 3 1 3 3v19l-13-6a2 2 0 0 0-2 0L44 79l-10-5a2 2 0 0 0-2 0l-9 7V55c0-2 2-3 4-3h41Z M40 62c0 2-2 4-5 4s-5-2-5-4 2-4 5-4 5 2 5 4Z" />
                    </symbol>
                </defs>
            </svg>

            <Modal
                show={success != null}
                onHide={handleCloseSuccessModal}
                size="lg"
                className="modal"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>✅ Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <b>Dataset DID:</b> {success?.datasetDID}
                        <br />
                        <b>Governance VC tx:</b>{" "}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={EXPLORER + "/" + success?.govVCTx}
                        >
                            {success?.govVCTx}
                        </a>
                        <br />
                        <b>Description VC tx:</b>{" "}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={EXPLORER + "/" + success?.descVCTx}
                        >
                            {success?.descVCTx}
                        </a>
                        <br />
                        <b>Publication VC tx:</b>{" "}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={EXPLORER + "/" + success?.pubVCTx}
                        >
                            {success?.pubVCTx}
                        </a>
                        <br />
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn bgblack modal-btn"
                        onClick={handleCloseSuccessModal}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={error != null}
                onHide={handleCloseErrorModal}
                size="lg"
                className="modal"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>❌ Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{error}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn bgblack modal-btn"
                        onClick={handleCloseErrorModal}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default Contribute;
