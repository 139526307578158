// src/pages/TermsAndConditions.js
import React from "react";
import { Container } from "react-bootstrap";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import BackToTop from "../components/BackToTop/BackToTop";

function DisclaimerNft() {
    return (
        <div className="Contribute p-3 p-sm-4" style={{ minHeight: "100vh" }}>
            <Container className="">
                <Header />
                <div className="w-75 mx-auto ">
                    <h1 className="mt-5 pt-5 mb-5">
                        {" "}
                        Disclaimer for NFT Creation in the KATAI Project
                    </h1>
                    <p>
                        This disclaimer is intended to ensure transparency and a
                        clear understanding of the nature and purpose of the
                        NFTs associated with the KATAI project. The OKP4
                        Association is committed to maintaining an ethical and
                        non-commercial approach to recognizing the valuable
                        contributions of its community.
                    </p>
                    <p>
                        The OKP4 Association, responsible for the KATAI project,
                        issues this disclaimer to clarify the purpose and nature
                        of the NFTs created in conjunction with this project.
                    </p>
                    <p>
                        The KATAI project, accessible at{" "}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            className="red"
                            href="https://katai.axone.xyz/"
                        >
                            https://katai.axone.xyz
                        </a>
                        , involves the issuance of NFTs as a form of recognition
                        and symbolic reward for contributors who provide images
                        to collaboratively train the algorithm. These NFTs are
                        not created for commercial purposes, nor are they
                        intended for commercialization by the OKP4 Association.
                    </p>
                    <p>
                        By participating in the KATAI project and receiving an
                        NFT, contributors acknowledge and agree that:
                    </p>
                    <h2 className="mt-5">1. Non-Commercial Intent</h2>
                    <p>
                        The NFTs issued by the OKP4 Association are strictly for
                        symbolic recognition and do not hold any commercial
                        value. They are not intended to be sold, traded, or
                        otherwise monetized by the Association.
                    </p>
                    <h2 className="mt-5">2. Purpose of NFTs: </h2>
                    <p>
                        The primary objective of issuing these NFTs is to
                        acknowledge the valuable contributions of individuals to
                        the training of the algorithm and to showcase the
                        capabilities of the decentralized Axone protocol, which
                        can be explored further at{" "}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            className="red"
                            href="https://axone.xyz/"
                        >
                            https://axone.xyz
                        </a>
                    </p>
                    <h2 className="mt-5">3. Contribution and Recognition:</h2>
                    <p>
                        Contributors provide their images voluntarily to support
                        the collaborative effort in enhancing the algorithm's
                        performance. The issuance of an NFT is a token of
                        appreciation for the contributor's involvement and does
                        not imply any form of employment, partnership, or
                        financial compensation.
                    </p>
                    <h2 className="mt-5">4. No Financial Expectation:</h2>
                    <p>
                        Contributors should not expect any financial return or
                        profit from receiving these NFTs. The OKP4 Association
                        expressly disclaims any responsibility or liability for
                        any financial loss or other consequences that
                        contributors may incur in relation to these NFTs.
                    </p>

                    <p>
                        By continuing to participate in the KATAI project and
                        accepting the NFTs, contributors affirm their
                        understanding and acceptance of the terms outlined in
                        this disclaimer.
                    </p>
                    <p>
                        For any further inquiries, please contact the OKP4
                        Association directly through the contact information
                        provided on the KATAI project website.
                    </p>
                </div>
                <BackToTop />
                <Footer />
            </Container>
        </div>
    );
}

export default DisclaimerNft;
