import React from "react";
import Cat1 from "./Chamurai-01.png";
import Cat2 from "./Chamurai-02.png";
import Cat3 from "./Chamurai-03.png";
import Cat4 from "./Chamurai-04.png";
import "./galerie.css";

const images = [
    { src: Cat1, alt: "A cat dressed-up as a Samuraï" },
    { src: Cat2, alt: "Another cat dressed-up as a Samuraï" },
    { src: Cat3, alt: "Again a cat dressed-up as a Samuraï" },
    { src: Cat4, alt: "Finally a cat dressed-up as a Samuraï" },
];

function Galerie() {
    return (
        <div className="galerie-container">
            {images.map((image, index) => (
                <img
                    key={index}
                    src={image.src}
                    alt={image.alt}
                    className={`galerie-item galerie-item-${index + 1}`}
                />
            ))}
        </div>
    );
}

export default Galerie;
