import React from "react";
import monVideo from "./Chamurai-01.mp4";

const CatHeroImg = ({ alt = "" }) => {
    return (
        <video autoPlay loop muted className="p-4 pt-0 cat-hero-img">
            <source src={monVideo} type="video/mp4" />
            {alt}
        </video>
    );
};

export default CatHeroImg;
