import React, { useEffect, useState } from "react";
import "./hero.css";


const FlippingWords = ({ words, interval }) => {
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [isFading, setIsFading] = useState(false);

    useEffect(() => {
        const changeWord = () => {
            setIsFading(true);
            setTimeout(() => {
                setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
                setIsFading(false);
            }, 700); 
        };

        const wordInterval = setInterval(changeWord, interval);

        return () => clearInterval(wordInterval);
    }, [words, interval]);

    return (
        <span className={`flipping-word ${isFading ? 'hidden' : 'visible'}`}>
            {words[currentWordIndex]}
        </span>
    );
};

export default FlippingWords;