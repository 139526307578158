const dentrite = {
    chainId: "axone-dentrite-1",
    chainName: "Axone testnet",
    rpc: "https://api.dentrite.axone.xyz:443/rpc",
    rest: "https://api.dentrite.axone.xyz",
    bip44: {
        coinType: 118,
    },
    bech32Config: {
        bech32PrefixAccAddr: "axone",
        bech32PrefixAccPub: "axonepub",
        bech32PrefixValAddr: "axonevaloper",
        bech32PrefixValPub: "axonevaloperpub",
        bech32PrefixConsAddr: "axonevalcons",
        bech32PrefixConsPub: "axonevalconspub",
    },
    currencies: [
        {
            coinDenom: "AXONE",
            coinMinimalDenom: "uaxone",
            coinDecimals: 6,
        },
    ],
    feeCurrencies: [
        {
            coinDenom: "AXONE",
            coinMinimalDenom: "uaxone",
            coinDecimals: 6,
            gasPriceStep: {
                low: 0.01,
                average: 0.025,
                high: 0.03,
            },
        },
    ],
    stakeCurrency: {
        coinDenom: "AXONE",
        coinMinimalDenom: "uaxone",
        coinDecimals: 6,
    },
    coinType: 118,
};

export default dentrite;
