import React from "react";
import { Modal, Button, Row, Col, Container } from "react-bootstrap";

function ImagePopup({ show, handleClose, title, text, images }) {
    return (
        <Modal show={show} onHide={handleClose} size="lg" className="p-3 rounded">
            <Modal.Header closeButton className="bg-red text-center">
                <Modal.Title className="w-100"><div className="text-center mx-auto "
                                dangerouslySetInnerHTML={{
                                    __html: title,
                                }}
                            ></div></Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-black">
                <Container className="bg-black">
                    <Row className="bg-black p-0 m-0">
                        <Col xs={12} md={3} className="bg-black rounded p-3 me-4">
                            <p className="text-center">Fine tuned model</p>
                            <img
                                src={images.finetuned}
                                alt="Popup"
                                style={{ width: "100%", marginBottom: "15px" }}
                            />
                            <p className="text-center">Standard model</p>
                            <img
                                src={images.standard}
                                alt="Popup"
                                style={{ width: "100%", marginBottom: "15px" }}
                            />
                        </Col>
                        <Col xs={12} md={8} className="bg-black text-start rounded p-3">
                            <div dangerouslySetInnerHTML={{ __html: text }} />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ImagePopup;
