import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./header.css";
import Wallet from "../Wallet/wallet";
import { Link } from 'react-router-dom';

function Header() {
    const links = [
        { id: "/#how-it-works", label: "How does it work?" },
        { id: "/#future", label: "The future of AI" },
        { id: "/#rewards", label: "Rewards" },
        { id: "/#axone", label: "About Axone" },
        { id: "/contribute", label: "Contribute" },
    ];

    return (
        <Navbar expand="xl" className="bgblack p-0">
            <Container className="py-3">
                <Navbar.Brand as={Link} to="/">
                    <img
                        alt=""
                        src="/img/KATAI.svg"
                        width="180"
                        height="40"
                        className="d-inline-block align-top"
                    />{" "}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {links.map((link) => (
                            <Nav.Link key={link.id} as={Link} to={`${link.id}`}>
                                {link.label}
                            </Nav.Link>
                        ))}
                    </Nav>
                    <Wallet />
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
