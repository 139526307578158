import React, { useRef, useState, useEffect, useCallback } from "react";
import { useGesture } from "@use-gesture/react";
import { useSpring, animated } from "@react-spring/web";
import { Card, Container, Button } from "react-bootstrap";
import "./roadmap.css";

const leftArrowSvg = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15 19L8 12L15 5"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const rightArrowSvg = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9 5L16 12L9 19"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const Roadmap = ({ events }) => {
    const containerRef = useRef();
    const [{ x }, api] = useSpring(() => ({ x: -100 }));
    const [containerWidth, setContainerWidth] = useState(0);
    const [clientWidth, setClientWidth] = useState(0);
    const [blurredIndices, setBlurredIndices] = useState([]);

    useEffect(() => {
        if (containerRef.current) {
            setContainerWidth(containerRef.current.scrollWidth);
            setClientWidth(containerRef.current.clientWidth);
        }
    }, [events]);

    const updateBlurredIndices = useCallback(() => {
        if (containerRef.current) {
            const rightEdge = -x.get() + clientWidth;
            const newBlurredIndices = events.map((_, index) => {
                const cardLeftEdge = index * 320; // 300px card width + 20px margin
                return cardLeftEdge >= rightEdge;
            });
            setBlurredIndices(newBlurredIndices);
        }
    }, [x, clientWidth, events]);

    useEffect(() => {
        const interval = setInterval(updateBlurredIndices, 50);
        return () => clearInterval(interval);
    }, [updateBlurredIndices]);

    const bind = useGesture({
        onDrag: ({ offset: [x] }) => {
            if (containerRef.current) {
                const maxOffset = -containerWidth + clientWidth;
                api.start({ x: Math.min(0, Math.max(x, maxOffset)) });
            }
        },
        onWheel: ({ offset: [x] }) => {
            if (containerRef.current) {
                const maxOffset = -containerWidth + clientWidth;
                api.start({ x: Math.min(0, Math.max(x, maxOffset)) });
            }
        },
    });

    const scrollLeft = () => {
        if (containerRef.current) {
            api.start({ x: Math.min(x.get() + 300, 0) });
        }
    };

    const scrollRight = () => {
        if (containerRef.current) {
            api.start({
                x: Math.max(x.get() - 300, -containerWidth + clientWidth),
            });
        }
    };

    return (
        <>
            <Container fluid className="pb-4">
                <h2 className="text-start">Roadmap, new features to come</h2>
                <p className="text-start col-12 col-md-9">
                    Explore the milestones in our journey towards building a
                    decentralized, community-driven AI with KatAI. From data
                    collection to AI training and reward distribution, witness
                    the collaborative process that underscores our commitment to
                    privacy, sovereignty, and ethical innovation. Join us as we
                    pave the way for a new era of AI development, where every
                    contribution is valued and rewarded.
                </p>
            </Container>
            <Container fluid className="roadmap-container py-5">
                <Button
                    className="position-absolute roadmap-btn"
                    style={{
                        left: "120px",
                        top: "40px",
                        transform: "translateY(-50%)",
                        zIndex: 1,
                    }}
                    onClick={scrollLeft}
                >
                    {leftArrowSvg}
                </Button>
                <Button
                    className="position-absolute roadmap-btn"
                    style={{
                        left: "190px",
                        top: "40px",
                        transform: "translateY(-50%)",
                        zIndex: 1,
                    }}
                    onClick={scrollRight}
                >
                    {rightArrowSvg}
                </Button>
                <animated.div
                    {...bind()}
                    ref={containerRef}
                    className="roadmap-content py-5"
                    style={{
                        x,
                    }}
                >
                    {events.map((event, index) => (
                        <Card
                            key={index}
                            style={{
                                width: "300px",
                                marginRight: "20px",
                                flexShrink: 0,
                                filter: blurredIndices[index]
                                    ? "blur(3px)"
                                    : "none",
                            }}
                        >
                            <Card.Img variant="top" src={event.image} />
                            <Card.Body>
                                <Card.Title>{event.title}</Card.Title>
                                <Card.Text>{event.description}</Card.Text>
                            </Card.Body>
                            <Card.Footer>{event.deadline}</Card.Footer>
                        </Card>
                    ))}
                </animated.div>
                <div className="roadmap-overlay-left"></div>
                <div className="roadmap-overlay-right"></div>
            </Container>
        </>
    );
};

export default Roadmap;
