import React from "react";

function LogoAxone() {
    return (
        <img
            src={process.env.PUBLIC_URL + "/img/Axone.svg"}
            alt="Logo - Axone"
            className="text-center mx-auto w-75"
        />
    );
}

export default LogoAxone;
