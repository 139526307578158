import React from "react";

function Logo() {
    return (
        <img
            src={process.env.PUBLIC_URL + "/img/KATAI.svg"}
            alt="Logo - KatAI"
            className="w-100"
        />
    );
}

export default Logo;
