import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./galerie.css";
import Galerie from "./Galerie";
import "aos/dist/aos.css";
import { Button } from "react-bootstrap";

function RevolutionaryProject() {
    return (
        <Row id="rewards">
            <Col
                lg={6}
                className="text-start"
                data-aos="fade-right"
                data-aos-delay="1000"
            >
                <h2 className="" xs={12}>
                    Take part in a <span className="red">revolutionnary</span>
                    <br /> project and get <span className="red">rewarded</span>
                </h2>
                <p>
                    The innovation behind this project lies in its unique fusion
                    of blockchain technology with generative AI, driven by
                    community collaboration. By inviting users to directly
                    contribute data, participate in AI training, and engage in
                    governance, we're not just building an AI; we're pioneering
                    a new model of technological development that is open,
                    transparent, and inclusive.
                </p>
                <p>
                    Early adopters play a critical role in this ecosystem,
                    providing the foundational data and feedback necessary for
                    the AI to learn and grow. As visionaries, their
                    contributions are invaluable, setting the stage for a more
                    ethical and user-driven future of AI. In recognition of
                    their pioneering spirit and contribution, these early users
                    will be rewarded, not just with tokens or digital assets,
                    but by acknowledging their role in shaping the future of AI
                    and technology, ensuring they have a stake in the project's
                    success and evolution.
                </p>
                <a
                    href="https://www.stargaze.zone/p/katai/collections"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-right float-end py-5"
                >
                    <Button className="btn bg-red p-3">
                        Our collections on Stargaze
                    </Button>
                </a>
            </Col>
            <Col
                lg={6}
                className="d-flex align-items-center text-center"
                data-aos="fade-left"
                data-aos-delay="800"
            >
                <Galerie />
            </Col>
        </Row>
    );
}

export default RevolutionaryProject;
