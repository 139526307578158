import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Contribute from "./pages/Contribute";
import { GrazProvider } from "graz";
import React, { useEffect } from "react";
import dentrite from "./chainInfo";
import TermsAndConditions from "./pages/TermsAndConditions";
import DisclaimerNft from "./pages/DisclaimerNft";

function App() {
    useEffect(() => {
        window._mtm = window._mtm || [];
        window._mtm.push({
            "mtm.startTime": new Date().getTime(),
            event: "mtm.Start",
        });

        const script = document.createElement("script");
        script.async = true;
        script.src = "https://analytics.axone.xyz/js/container_fG3abJhU.js";
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <GrazProvider
            grazOptions={{
                chains: [dentrite],
            }}
        >
            <Router>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/contribute" element={<Contribute />} />
                    <Route path="*" element={<HomePage />} />
                    <Route
                        path="/terms-and-conditions"
                        element={<TermsAndConditions />}
                    />
                    <Route path="/disclaimer-nft" element={<DisclaimerNft />} />
                </Routes>
            </Router>
        </GrazProvider>
    );
}

export default App;
