import { Chrono } from "react-chrono";
import "./timeline.css";
import { ReactComponent as CatPicture } from "./catPicture.svg";
import { ReactComponent as Computer } from "./computer.svg";
import { ReactComponent as Reward } from "./reward.svg";
import { ReactComponent as Training } from "./training.svg";
import { ReactComponent as Wallet } from "./wallet.svg";

const items = [
    {
        title: <Wallet />,
        cardTitle: "REGISTER WITH YOUR WALLET",
        cardSubtitle:
            "Securely sign up by connecting your digital wallet, establishing your unique identity within our protocol.",
    },
    {
        title: <CatPicture />,
        cardTitle: "INDEX YOUR CAT PICTURES",
        cardSubtitle:
            "Index and categorize your cat photos in axone protocol, contributing to the diverse pool of training data.",
    },
    {
        title: <Training />,
        cardTitle: "JOIN US FOR THE COLLABORATIVE AI TRAINING",
        cardSubtitle:
            "Participate in the AI training process with us during one of our live sessions.",
    },
    {
        title: <Computer />,
        cardTitle: "TEST YOUR PROMPTS",
        cardSubtitle:
            "Engage with the trained AI by submitting prompts to generate custom cat images, experiencing the power of collaborative AI firsthand.",
    },
    {
        title: <Reward />,
        cardTitle: "GET REWARDS",
        cardSubtitle:
            "Receive rewards for your contributions to the project, recognizing your valuable role in advancing our collaborative AI technology.",
    },
];

const theme = {
    cardBgColor: "#000000",
    cardSubtitleColor: "#ffffff",
    cardTitleColor: "#ffffff",
    primary: "#ffffff",
    cardMediaBgColor: "#000000",
    secondary: "#000000",
};

const VerticalAlternatingTimeline = () => {
    return (
        <Chrono
            items={items}
            theme={theme}
            mode="VERTICAL_ALTERNATING"
            cardHeight="0"
            disableClickOnCircle="true"
            timelinePointDimension="20"
            timelinePointShape="circle"
            fontSizes={{
                cardSubtitle: "13px",
                cardText: "0.8rem",
                cardTitle: "15px",
                title: "15px",
            }}
            itemWidth={150}
        />
    );
};

export default VerticalAlternatingTimeline;
