import React, { useEffect } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Hero from "../components/Hero/Hero";
import { Container } from "react-bootstrap";
import FirstProject from "../components/FirstProject/FirstProject";
import Solution from "../components/Solution/Solution";
import RevolutionaryProject from "../components/RevolutionaryProject/RevolutionaryProject";
import Axone from "../components/Axone/Axone";
import BackToTop from "../components/BackToTop/BackToTop";
import ScrollToAnchor from "../components/ScrollToAnchor/ScrollToAnchor";
import AOS from "aos";
import "aos/dist/aos.css";
import "./homePage.css";
import Carousel from "../components/Carousel/Carousel";
import Roadmap from "../components/Roadmap/Roadmap";
import events from "../events.json";
import CurrentSprint from "../components/CurrentSprint/currentSprint";

function HomePage() {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
            offset: 200,
            anchorPlacement: "top-bottom",
        });
    }, []);

    return (
        <div className="HomePage">
            <Container className="p-3 p-sm-4">
                <Header />
                <Hero data-aos="fade-right" data-aos-offset="300" />
                <CurrentSprint data-aos="fade-up" data-aos-delay="400"/>
            </Container>
            <Container fluid className="p-3 p-sm-4 bg-red">
                <Carousel />
            </Container>
            <Container className="p-3 p-sm-4">
                <FirstProject data-aos="fade-up" data-aos-delay="200" />
                <Roadmap
                    data-aos="fade-down"
                    data-aos-delay="400"
                    events={events}
                />
                <Solution data-aos="fade-down" data-aos-delay="400" />
                <RevolutionaryProject />
                <Axone data-aos="fade-up" data-aos-offset="800" />
            </Container>
            <Container fluid className="bgblack">
                <Footer />
                <BackToTop />
            </Container>
            <ScrollToAnchor />
        </div>
    );
}

export default HomePage;
