import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import carouselData from "./carouselData";
import ImagePopup from "./ImagePopup";
import "./Carousel.css";
import { Container } from "react-bootstrap";

function Carousel() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const [popupContent, setPopupContent] = useState({
        title: "",
        text: "",
        images: { standard: "", finetuned: "", metrics: "" },
    });
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleImageClick = (title, text, disabled, images) => {
        if (!disabled) {
            setPopupContent({ title, text, images });
            setShowPopup(true);
        }
    };

    const handleClosePopup = () => setShowPopup(false);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselData.length);
    };

    const prevSlide = () => {
        setCurrentIndex(
            (prevIndex) =>
                (prevIndex - 1 + carouselData.length) % carouselData.length
        );
    };

    const visibleImagesCount = isMobile ? 1 : 3;
    const visibleImages = [];
    for (let i = 0; i < visibleImagesCount; i++) {
        const index = (currentIndex + i) % carouselData.length;
        visibleImages.push(carouselData[index]);
    }

    const slideAnimation = useSpring({
        transform: `translateX(-${currentIndex * (100 / visibleImagesCount)}%)`,
    });

    return (
        <Container>
            <Row className="p-4 p-sm-5 bg-red" id="results">
                <Col xxl={3} className="text-start">
                    <h2 className="mb-3">
                        Results ! <br></br>Road to Collaborative AI
                    </h2>
                    <p>
                        Discover the community creation. <br></br>Are you as
                        early as you said ?
                    </p>
                </Col>
                <Col xxl={9} className="katai-carousel-container">
                    <div className="katai-carousel">
                        <button
                            className="katai-carousel-button"
                            onClick={prevSlide}
                        >
                            ‹
                        </button>
                        <div className="katai-carousel-viewport">
                            <animated.div
                                className="katai-carousel-slider"
                                style={slideAnimation}
                            >
                                {carouselData.map((image, index) => (
                                    <div
                                        className={`katai-carousel-item ${
                                            image.disabled ? "disabled" : ""
                                        }`}
                                        key={index}
                                        onClick={() =>
                                            handleImageClick(
                                                image.title,
                                                image.text,
                                                image.disabled,
                                                image.images
                                            )
                                        }
                                        style={{
                                            flex: `0 0 ${
                                                100 / visibleImagesCount
                                            }%`,
                                        }}
                                    >
                                        <img
                                            src={image.images.finetuned}
                                            alt={image.title}
                                            className="w-75 mb-4"
                                        />
                                        {image.disabled && (
                                            <div className="katai-lock-icon">
                                                🔒
                                            </div>
                                        )}
                                        <div
                                            className="katai-carousel-title"
                                            dangerouslySetInnerHTML={{
                                                __html: image.title,
                                            }}
                                        ></div>
                                    </div>
                                ))}
                            </animated.div>
                        </div>
                        <button
                            className="katai-carousel-button"
                            onClick={nextSlide}
                        >
                            ›
                        </button>
                    </div>
                    <ImagePopup
                        show={showPopup}
                        handleClose={handleClosePopup}
                        title={popupContent.title}
                        text={popupContent.text}
                        images={popupContent.images}
                    />
                </Col>
            </Row>
        </Container>
    );
}

export default Carousel;
