import { useNavigate } from "react-router-dom";

function HeroButtons() {
    const navigate = useNavigate();

    return (
        <div className="buttons-hero mt-4 d-flex align-items-end justify-content-end">
            <button
                onClick={() => navigate("#how-it-works")}
                className="btn px-4 py-3 me-4 bgblack"
            >
                About KatAI
            </button>
            <button
                onClick={() => navigate("/contribute")}
                className="btn px-4 py-3 bg-red"
            >
                Contribute
            </button>
        </div>
    );
}

export default HeroButtons;
