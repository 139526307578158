import React from "react";
import monImage from "./SolutionImg.png";

const SolutionImg = ({ alt = "" }) => {
  return (
    <img
      src={monImage}
      alt={alt}
      fluid="true"
      className="w-50 p-4 pt-0 text-center mx-auto"
    />
  );
};

export default SolutionImg;
