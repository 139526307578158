// src/components/Footer.js
import React from "react";
import "./footer.css";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

function Footer() {
    return (
        <footer>
            <Container className="p-5 pb-2">
                <p>
                    © 2024 Axone - All right reserved -{" "}
                    <Link className="terms" to="/terms-and-conditions">
                        Terms and Conditions
                    </Link>{" "}
                    -{" "}
                    <Link className="terms" to="/disclaimer-nft">
                        Disclaimer for NFT
                    </Link>
                </p>
            </Container>
        </footer>
    );
}

export default Footer;
