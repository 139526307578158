// src/pages/TermsAndConditions.js
import React from "react";
import { Container } from "react-bootstrap";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import BackToTop from "../components/BackToTop/BackToTop";

function TermsAndConditions() {
    return (
        <div className="Contribute p-3 p-sm-4" style={{ minHeight: "100vh" }}>
            <Container className="">
                <Header />
                <div className="w-75 mx-auto ">
                    <h1 className="mt-5 pt-5 mb-5">
                        {" "}
                        Terms and Conditions for the KATAI Website
                    </h1>
                    <p>
                        Welcome to the KATAI project website, operated by the
                        OKP4 Association, a non-profit association under Swiss
                        law. By accessing and using this website, you agree to
                        comply with and be bound by the following terms and
                        conditions.
                    </p>
                    <h2>1. Operator Information</h2>
                    <p>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            className="red"
                            href="https://katai.axone.xyz"
                        >
                            {" "}
                            The KATAI website{" "}
                        </a>{" "}
                        is operated by: OKP4 Association Registered in the
                        commercial register of Neuchâtel, Switzerland
                        Registration Number: CHE-436.849.130 Head Office: C/O
                        Coworking Neuchâtel, Rue St Honoré, 10 - 2000 Neuchâtel
                        - Switzerland
                    </p>
                    <h2 className="mt-5">2. Purpose of the Website</h2>
                    <p>
                        {" "}
                        The KATAI project aims to animate an open-source project
                        for a collaboratively trained AI algorithm. This project
                        serves as a use case example for the Axone protocol, an
                        open-source, decentralized infrastructure for
                        orchestrating digital resources based on fully
                        composable rules, which can be explored further at{" "}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            className="red"
                            href="https://axone.xyz"
                        >
                            https://axone.xyz/
                        </a>
                        .{" "}
                    </p>
                    <h2 className="mt-5">3. Non-Commercial Nature</h2>
                    <p>
                        {" "}
                        The goals of the KATAI project are strictly
                        non-commercial. The primary aim is to provide developers
                        and supporters of the Axone protocol with an
                        experimental and sharing platform to explore the
                        capabilities offered by Axone. The OKP4 Association does
                        not engage in any commercial activities through this
                        project.{" "}
                    </p>
                    <h2 className="mt-5">4. Open Source License </h2>
                    <p>
                        The KATAI project and the Axone protocol operate under
                        an open-source license. By participating in the KATAI
                        project, you agree to comply with the terms of the
                        applicable open-source licenses. Detailed information
                        about the specific licenses can be found within the
                        project repositories.
                    </p>
                    <h2 className="mt-5">
                        5. Contributions and Intellectual Property
                    </h2>
                    <p>
                        Participants contributing to the KATAI project do so on
                        a voluntary basis. All contributions, including but not
                        limited to code, images, and data, are subject to the
                        open-source license governing the project. Contributors
                        grant the OKP4 Association and the community a
                        non-exclusive, royalty-free license to use, modify, and
                        distribute their contributions within the project's
                        scope.
                    </p>{" "}
                    <h2 className="mt-5">6. Disclaimer of Warranties</h2>
                    <p>
                        {" "}
                        The KATAI website and project are provided "as is"
                        without any warranties of any kind, either express or
                        implied. The OKP4 Association makes no warranties or
                        representations about the accuracy or completeness of
                        the content on this site or the availability,
                        reliability, or functionality of the project.{" "}
                    </p>
                    <h2 className="mt-5">7. Limitation of Liability</h2>
                    <p>
                        {" "}
                        In no event shall the OKP4 Association be liable for any
                        indirect, incidental, special, consequential, or
                        punitive damages arising out of or related to your use
                        of the KATAI website or participation in the project.
                        This includes but is not limited to, any errors or
                        omissions in any content, loss or damage of any kind
                        incurred as a result of the use of any content posted,
                        transmitted, or otherwise made available via the
                        website.{" "}
                    </p>
                    <h2 className="mt-5">
                        8. Changes to the Terms and Conditions
                    </h2>
                    <p>
                        {" "}
                        The OKP4 Association reserves the right to update or
                        modify these terms and conditions at any time without
                        prior notice. Your continued website use following any
                        changes constitutes your acceptance of the new terms and
                        conditions.
                    </p>
                    <h2 className="mt-5">9. Governing Law</h2>
                    <p>
                        {" "}
                        These terms and conditions are governed by and construed
                        in accordance with the laws of Switzerland. Any disputes
                        arising out of or in connection with them shall be
                        subject to the exclusive jurisdiction of the courts of
                        Neuchâtel, Switzerland.{" "}
                    </p>
                    <h2 className="mt-5">10. Contact Information</h2>
                    <p>
                        {" "}
                        If you have any questions or concerns about these terms
                        and conditions, please contact the OKP4 Association
                        using the contact information provided on the KATAI
                        project website. You agree to the terms and conditions
                        outlined above by using this website. If you do not
                        agree to these terms, please do not use the KATAI
                        website.
                    </p>
                    <p>
                        These terms and conditions ensure clarity and
                        transparency regarding the operation and purpose of the
                        KATAI project, emphasizing its non-commercial nature and
                        open-source collaboration principles.
                    </p>
                </div>
                <BackToTop />
                <Footer />
            </Container>
        </div>
    );
}

export default TermsAndConditions;
