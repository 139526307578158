import React, { useState, useEffect } from "react";

const BackToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);

        return () => {
            window.removeEventListener("scroll", toggleVisibility);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        isVisible && (
            <button onClick={scrollToTop} style={styles}>
                ↑
            </button>
        )
    );
};

const styles = {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    zIndex: "1000",
    border: "none",
    outline: "none",
    backgroundColor: "#D10202",
    color: "white",
    cursor: "pointer",
    borderRadius: "5px",
    width: "40px",
    height: "40px",
};

export default BackToTop;
