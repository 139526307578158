import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./firstproject.css";
import FirstProjectImg from "./FirstProjectImg";

function FirstProject(props) {
    return (
        <Row className="" id="how-it-works" {...props}>
            <Col lg={6} className="text-start py-3">
                <h2 className="">KatAI, the first AI project based on Axone</h2>
                <p>
                    KatAI, under the Axone protocol, aims to revolutionize
                    generative AI development by utilizing community-contributed
                    data. It focuses on creating AI-generated cat images from
                    prompts. This initiative underscores a commitment to data
                    privacy, sovereignty, and decentralized management, ensuring
                    contributors retain control over their data.
                </p>
                <p>
                    KatAI represents a unique opportunity for those interested
                    in AI, decentralization, and community-driven efforts,
                    offering a platform to contribute to ethical generative AI
                    development. Join us in pioneering the future of AI, where
                    every contribution is acknowledged and rewarded.
                </p>
            </Col>
            <Col lg={6} className="d-flex align-items-center text-center">
                <FirstProjectImg />
            </Col>
        </Row>
    );
}

export default FirstProject;
