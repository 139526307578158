import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LogoAxone from "./LogoAxone";

function Axone(props) {
    return (
        <div>
            <Row className="justify-content-center" id="axone" {...props}>
                <Col lg={7} className="d-flex flex-column ">
                    <h2 className="text-center mb-4">
                        A layer one built for the emergence of AGI
                    </h2>
                    <p>
                        Built on the Cosmos SDK, Axone is specifically designed
                        to create trust, composability, and incentives to
                        maximize collaborative value creation. It is an
                        alternative to centralized AI training. While big tech
                        companies are focused on acquiring more data to train
                        private models, Axone enables coordination at scale for
                        worldwide communities to train, deploy, use, and
                        collectively own their models.
                    </p>
                    <p>
                        While big tech companies are focused on acquiring more
                        data to train private models, Axone enables coordination
                        at scale for worldwide communities to train, deploy,
                        use, and collectively own their models.
                    </p>
                </Col>
            </Row>
            <Row className="" {...props}>
                <Col lg={6} className="d-flex align-items-center">
                    <a
                        href="https://axone.xyz"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="d-flex align-items-center text-center mx-auto py-5"
                    >
                        <LogoAxone />
                    </a>
                </Col>
                <Col lg={6} className="text-start">
                    <h2 className="text-right" lg={12}>
                        Axone, the Orchestration Layer for AI
                    </h2>
                    <p>
                        Axone is revolutionizing AI & Web3 Integration. It
                        emerges as the foundational layer orchestrating AI
                        technologies across Web3. We're connecting over 50
                        million GitHub developers from isolated open-source AI
                        initiatives to streamline and enhance AI workflows.
                    </p>
                </Col>
            </Row>
        </div>
    );
}

export default Axone;
