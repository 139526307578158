import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./CurrentSprint.css";
import { Link } from "react-router-dom";

function CurrentSprint(props) {
    return (
        <Link to="/contribute" style={{ textDecoration: "none" }}>
            <Row
                className="justify-content-center text-center rounded-xl mx-auto current-sprint mb-4"
                id="future"
                {...props}
                style={{ maxWidth: "800px", height: "300px" }}
            >
                <Col lg={6} className="d-flex flex-column bg-image">
                    <div style={{ height: "100%", width: "100%" }}></div>
                </Col>
                <Col
                    lg={6}
                    className="d-flex flex-column text-left p-4 justify-content-center align-items-center"
                >
                    <h2 className="text-lg">Current Sprint - Sprint #9</h2>
                    <p className=" mx-auto">
                    An old samurai cat sitting on a couch, watching a movie on TV as the end credits roll
                    </p>
                </Col>
            </Row>
        </Link>
    );
}

export default CurrentSprint;
