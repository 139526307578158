import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CatHeroImg from "./CatHeroImg";
import HeroButtons from "./HeroButtons";
import Logo from "../Header/Logo";
import FlippingWords from "./FlippingWords";
import "./hero.css";

const words = ["Train", "Own", "Govern"];

function Hero(props) {
    return (
        <Row className="d-flex" {...props}>
            <Col
                lg={7}
                className="d-flex flex-column text-start pe-4 order-2 order-lg-1"
            >
                <h1 className="mt-5">
                <span className="title-red">Collaboratively<br/></span>
                    <FlippingWords words={words} interval={2800} /> a generative AI.
                </h1>
                <p className="pe-5">
                    A new approach to data orchestration, interoperable workflows, and decentralized sovereignty. Learn how to turn private data into generative AI creations, fostering a community where everyone contributes, owns, and governs together.
                </p>
                <HeroButtons />
            </Col>
            <Col lg={5} className="order-1 order-lg-2">
                <CatHeroImg />
                <div className="logo-rotation text-center mx-auto">
                    <Logo />
                </div>
            </Col>
        </Row>
    );
}

export default Hero;
